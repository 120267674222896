$neutral-background: #F6FAF2;
$accent-fill-rest: #87C544;
$neutral-foreground-rest: #262626;
$kiks-bg-color: #116943;

.project_highlights{
    background-color: $neutral-background;
    color: #757575 !important;
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    box-sizing: border-box;
    padding-bottom: 15px;
    margin-top: -0rem;
    margin-bottom: 3rem;
    padding-top:1.5rem;
}

.project_highlights_title {
    position: relative;
    margin-top: 1rem;
    z-index: 100;
}

.highlight_container {
    color: black;
    box-sizing: border-box;
    min-width: 300px;
    margin: 1.5rem 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.highlight_element {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    border: 2px solid $accent-fill-rest;
    border-radius: 5px;
    padding: 0.5rem;
    min-width: 275px;
    max-width: 275px;
    background-color: white;
    padding: 10px;
    box-shadow: 5px 5px 10px $kiks-bg-color;
    margin-top: 1rem;
}

.highlight_element_logo_teaser {
    display: flex;
    flex-direction: row;
    //align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.highlight_element_teaser_text{
    text-overflow: wrap;
    color: $kiks-bg-color;
    margin: 0 15px;
    font-size: small;
    text-justify: inter-word;
}

.highlight_element h4 {
    color: $kiks-bg-color;
    font-size: 1.5rem;
}

.highlight_element .highlight_element_logo{
    max-width: 40;
    max-height: 40px;
    margin-right: 1rem;
}

.highlight_element .highlight_element_title {
    margin-top: 0rem;
    font-size: 2rem;
}

.project_highlights_learner_count_text {
    font-size:x-large;
    color: $kiks-bg-color;
    margin: 0 1rem;
    text-align: center;
}

.project_highlights_learner_count_count {
    font-size: 2rem;
    color: $kiks-bg-color;
    font-weight: 900;
}

.project_highlights_learner_count_container .nowrap {
    white-space: nowrap;
}

.project_highlights_learner_count_container .wrap {
    text-align: center;
}

.project_highlights_teaser{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.project_highlights_teaser bold{
    font-weight: 900;
    font-size: 2rem;
}

.project_highlights_teaser_text{
    font-size:1rem;
    color: $kiks-bg-color;
    margin: 0 1rem;
}

.project_highlights_learner_count_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.project_highlights_dwengo_logo{
    margin: 0 -1rem;
}